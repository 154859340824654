<script setup lang="ts">
import { getUrlForLocale } from '~/src/domain.ts';

defineProps<{
    link: string;
    locale: string;
}>();

const config = useConfig();
</script>

<template>
    <nuxt-link v-if="locale === config.locale" :to="link" :class="$attrs.class">
        <slot></slot>
    </nuxt-link>
    <a v-else-if="locale === 'external'" :href="link" target="_blank" rel="noopener" :class="$attrs.class">
        <slot></slot>
    </a>
    <a v-else :href="getUrlForLocale(locale) + link" :class="$attrs.class">
        <slot></slot>
    </a>
</template>
